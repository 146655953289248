.skipToContentLink {
  @apply absolute left-8 h-auto border-0 bg-secondary px-20 py-14 text-xl text-primary focus:outline-none focus-visible:outline-black;
  outline-offset: 0 !important;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 60;

  &:focus-visible,
  &:focus {
    transform: translateY(-5%);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
