// Importing global.scss
@import 'global';

// Importing tailwind.scss
@import 'tailwind';
@import 'quill/dist/quill.snow.css';
@import '~react-toastify/dist/ReactToastify.css';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';


// add the code bellow
@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
  }
