html,
body {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  text-align: left;
  margin: 0;
  padding: 0;
  color: var(--text);
  overflow-x: hidden; // todo: implement this
  background-color: var(--background);
  scroll-behavior: smooth;

  // root-font-size is 16px but is clamped
  // Read more about clamping & clamping function generator below:
  // https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
  // clamp( minSize, preferred, maxSize)

  // mobile viewport between 320px & 767px  - NO Clamping
  @media screen and (max-width: 767px) {
    font-size: clamp(0.7rem, 0.4852rem + 1.0738vw, 1rem);
  }

  // tablet viewport between 768 & 1023 - NO Clamping
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: clamp(0.8rem, 0.2092rem + 1.2308vw, 1rem);
  }

  // clamps desktop viewport between 1024 & 1540
  @media screen and (min-width: 1024px) {
    font-size: clamp(0.6rem, -0.1938rem + 1.2403vw, 1rem);
  }
}

.loadGradient {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #d7d7d8 8%, #f1f1f1 38%, #d7d7d8 73%);
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.ticketBgGradient {
  background: linear-gradient(-45deg, #00fcc9, #7c00f6);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -568px 0;
  }
  100% {
    background-position: 568px 0;
  }
}

.host-card {
  perspective: 22rem;

  &__inner {
    transform-style: preserve-3d;
  }

  &:hover &__inner {
    transform: rotateY(180deg);
  }

  &__front,
  &__back {
    backface-visibility: hidden;
  }

  &__front {
    transform: translateZ(1rem);
  }

  &__back {
    transform: rotateY(180deg) translateZ(1rem);
  }
}

/* Sets the phone number input background color to transparent. Couldn't do it from the component level. */
.PhoneInputInput {
  background-color: transparent !important;
}

.PhoneInputCountryIcon {
  border-radius: 0.2rem;
  overflow: hidden;
}

.PhoneInputCountrySelect {
  color: #000;
}

@keyframes movingBorder {
  0% {
    transform: rotate(0deg) scale(10);
  }

  100% {
    transform: rotate(-360deg) scale(10);
  }
}

.qr-border {
  animation: movingBorder 6s linear infinite;
}

// Calendar cell's bg color for today's date (Event List Calendar View)
.rbc-today {
  background-color: #acacac !important;
}

.ql-toolbar {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.first-letter-uppercase::first-letter {
  text-transform: uppercase;
}
